/* You can add global styles to this file, and also import other style files */
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

@import "node_modules/slick-carousel/slick/slick.css";
@import "node_modules/slick-carousel/slick/slick-theme.css";

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.color-navy-blue {
  color: #0c557f;
}
.cursor-pointer {
  cursor: pointer;
}
h2.title {
  text-align: center;
  font-size: 4rem;
  width: 100%;
  margin: auto;
}

h1.title {
  text-align: center;
  font-size: 3rem;
  width: 100%;
  margin: auto;
}

.gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(195, 240, 237, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}

.section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

img {
  max-width: 100%;
}

.container {
  max-width: 1400px;
  padding: 0px 20px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 700px;
  margin: auto;
  color: #a8a8a8;
}

a,
a:hover {
  text-decoration: none;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tdr-button {
  background-color: #fff;
  padding: 12px 25px;
  border-radius: 24px;
  border: 1px solid;
  font-size: 16px;
  display: inline-block;
  color: #000;
}

.tdr-button.sec {
  background-color: transparent;
  border-color: transparent;
}

.tdr-button:hover {
  color: #000;
}

.tdr-button.main:after {
  content: ">";
  font-weight: bold;
  position: absolute;
  right: 16px;
}

.tdr-button.main {
  position: relative;
  padding-right: 40px;
}

.tdr-button.br {
  border-radius: 10px;
  // max-width: 200px;
  // width: 100%;
  width: auto !important;
  min-width: 200px;
  text-align: center;
}

.get-btns {
  display: flex;
  justify-content: end;
}

.get-btn {
  border-radius: 15px;
  border: 1px solid #000;
  background: #fff;
  width: 480px;
  height: 60px;
  color: #000;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
}

.flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.border-radius-50 {
  border-radius: 50px !important;
}

.pill-image {
  border-radius: 50rem !important;
}

.bordered-text {
  overflow: hidden;
  text-align: center;
}

.bordered-text:before,
.bordered-text:after {
  background-color: #766f6f;
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.bordered-text:before {
  right: 0.5em;
  margin-left: -50%;
}

.bordered-text:after {
  left: 0.5em;
  margin-right: -50%;
}

.button-grp {
  margin-top: 3rem;
  font-weight: 600;
}

@media screen and (max-width: 1600px) {
  .get-btn {
    width: 440px;
    height: 50px;
    font-size: 24px;
  }
}
@media screen and (max-width: 1200px) {
  .get-btn {
    width: 340px;
    height: 50px;
    font-size: 22px;
  }
}
@media screen and (max-width: 991px) {
  .get-btn {
    width: 260px;
    height: 40px;
    font-size: 18px;
  }
  .pill-image {
    border-radius: 50px !important;
  }
}
@media only screen and (max-width: 768px) {
  .flex {
    display: block;
    text-align: center;
  }
  .navbar {
    padding-top: 0;
  }
}
@media screen and (max-width: 576px) {
  .get-btns {
    display: revert;
    text-align: center;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
